import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Grid, 
  Paper,
  useTheme,
  useMediaQuery
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const benefits = [
    {
      icon: <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />,
      title: "Objective Score",
      description: "See how your team performs in collaboration, ownership, and strategic execution."
    },
    {
      icon: <LightbulbIcon sx={{ fontSize: 40 }} />,
      title: "Clear Insights",
      description: "Get immediate recommendations based on your score."
    },
    {
      icon: <HeadsetMicIcon sx={{ fontSize: 40 }} />,
      title: "Free Expert Call (optional)",
      description: "Discuss your results in a free 20-minute leadership consult."
    }
  ];

  return (
    <Box>
      {/* Header Section */}
      <Box 
        sx={{ 
          bgcolor: 'primary.dark',
          color: 'white',
          py: 8,
          textAlign: 'center'
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h2" component="h1" gutterBottom>
            Is your leadership team ready to scale?
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Take the free Leadership Performance Test and discover in 5 minutes where your team stands—and where growth bottlenecks are.
          </Typography>
          <Button 
            variant="contained" 
            size="large"
            sx={{ 
              bgcolor: 'primary.light',
              '&:hover': {
                bgcolor: 'primary.main'
              }
            }}
            onClick={() => navigate('/welcome')}
          >
            Take the Free Test
          </Button>
        </Container>
      </Box>

      {/* Benefits Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4, 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <Box sx={{ color: 'primary.main', mb: 2 }}>
                  {benefit.icon}
                </Box>
                <Typography variant="h6" gutterBottom>
                  {benefit.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {benefit.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Target Audience Section */}
      <Box sx={{ bgcolor: 'grey.50', py: 6 }}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom textAlign="center">
            Who it's for
          </Typography>
          <Typography variant="body1" textAlign="center">
            It is best built for CEOs and founders or growing companies (50–250 employees).
          </Typography>
        </Container>
      </Box>

      {/* Testimonial Section */}
      <Box sx={{ bgcolor: 'grey.100', py: 8 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography 
                variant="h5" 
                sx={{ 
                  fontStyle: 'italic',
                  mb: 2
                }}
              >
                "The scan confirmed what the CEOs were sensing. The consult gave them clarity and the next steps."
              </Typography>
              <Typography variant="subtitle1">
                Geert Rietbergen, author of the #1 Amazon Bestseller: Soft Skills, Strong Results
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src="/placeholder-profile.jpg"
                alt="Geert Rietbergen"
                sx={{
                  width: '100%',
                  maxWidth: 300,
                  height: 'auto',
                  borderRadius: 2,
                  mx: 'auto',
                  display: 'block'
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home; 