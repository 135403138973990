import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Alert,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';

const InviteTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ceoId } = location.state || {};
  const [teamMembers, setTeamMembers] = useState([
    { name: '', email: '' },
  ]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddMember = () => {
    if (teamMembers.length < 8) {
      setTeamMembers([...teamMembers, { name: '', email: '' }]);
    }
  };

  const handleRemoveMember = (index) => {
    const newMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(newMembers);
  };

  const handleChange = (index, field, value) => {
    const newMembers = [...teamMembers];
    newMembers[index][field] = value;
    setTeamMembers(newMembers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!ceoId) {
      setError('No CEO ID found. Please return to the welcome page.');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/api/auth/invite-team',
        { ceoId, teamMembers },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      navigate('/thank-you');
    } catch (err) {
      setError(err.response?.data?.message || 'Something went wrong while sending the invitations');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            width: '100%',
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Invite Your Leadership Team
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            gutterBottom
            align="center"
            sx={{ mb: 4 }}
          >
            Add up to 8 team members who will take the test anonymously. Their
            answers will be compared with yours.
          </Typography>

          <form onSubmit={handleSubmit}>
            <List>
              {teamMembers.map((member, index) => (
                <ListItem key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Name (optional)"
                        value={member.name}
                        onChange={(e) =>
                          handleChange(index, 'name', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        required
                        fullWidth
                        label="Email"
                        type="email"
                        value={member.email}
                        onChange={(e) =>
                          handleChange(index, 'email', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveMember(index)}
                        disabled={teamMembers.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>

            {teamMembers.length < 8 && (
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddMember}
                sx={{ mb: 3 }}
              >
                Add Team Member
              </Button>
            )}

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Invitations'}
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default InviteTeam; 