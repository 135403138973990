import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import SurveyCompleted from './SurveyCompleted';
import TeamMemberNotFound from './TeamMemberNotFound';

const Survey = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [teamMember, setTeamMember] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [teamMemberNotFound, setTeamMemberNotFound] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [ceoInfo, setCeoInfo] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get(`/api/auth/verify-token/${token}`);
        const { ceoInfo, teamMember, questions, categories } = response.data;
        
        setCeoInfo(ceoInfo);
        setTeamMember(teamMember);
        setQuestions(questions);
        setCategories(categories);
        
        // Initialiseer answers object met null waarden
        const initialAnswers = {};
        questions.forEach(q => {
          initialAnswers[q._id] = null;
        });
        setAnswers(initialAnswers);
        
        if (categories.length > 0) {
          setCurrentCategory(categories[0]);
        }
        
        setLoading(false);
      } catch (err) {
        if (err.response?.data?.message === 'You have already completed this scan' || 
            err.response?.data?.message === 'Deze enquête is al ingevuld') {
          setIsCompleted(true);
        } else if (err.response?.data?.message === 'Team member not found' || 
                   err.response?.data?.message === 'Teamlid niet gevonden') {
          setTeamMemberNotFound(true);
        } else {
          setError(err.response?.data?.message || 'An error occurred');
        }
        setLoading(false);
      }
    };

    verifyToken();
  }, [token]);

  const handleAnswer = (questionId, value) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  const handleNext = () => {
    setActiveStep(prev => prev + 1);
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    try {
      // Controleer of alle vragen zijn beantwoord
      const unansweredQuestions = questions.filter(q => answers[q._id] === null);
      if (unansweredQuestions.length > 0) {
        setError('Alle vragen moeten beantwoord worden');
        setSubmitting(false);
        return;
      }

      // Filter lege antwoorden eruit
      const filteredAnswers = Object.entries(answers).reduce((acc, [key, value]) => {
        if (value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});

      await axios.post('/api/survey/submit', {
        token,
        answers: filteredAnswers
      });

      // Iedereen gaat naar de thank-you pagina
      navigate('/thank-you');
    } catch (err) {
      setError(err.response?.data?.message || 'Er is een fout opgetreden bij het versturen van de enquête');
      setSubmitting(false);
    }
  };

  const getQuestionsForCategory = (category) => {
    return questions.filter(q => q.category === category);
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (isCompleted) {
    return <SurveyCompleted />;
  }

  if (teamMemberNotFound) {
    return <TeamMemberNotFound />;
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Leadership Performance Scan
          </Typography>

          <Typography variant="subtitle1" gutterBottom align="center" color="text.secondary">
            Thank you for participating in this survey. Your answers are completely anonymous.
          </Typography>

          <Typography variant="body2" align="center" color="text.secondary" sx={{ mb: 4 }}>
            (1 = Strongly Disagree, 10 = Strongly Agree)
          </Typography>

          {/* Desktop Stepper */}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Stepper 
              activeStep={activeStep} 
              sx={{ my: 4 }}
            >
              {categories.map((category) => (
                <Step key={category}>
                  <StepLabel>{category}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Mobile Category Header */}
          <Box sx={{ 
            display: { xs: 'block', sm: 'none' },
            mb: 3,
            px: 2,
            py: 1,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            borderRadius: 1
          }}>
            <Typography variant="subtitle1" align="center">
              {currentCategory}
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            {categories.map((category, index) => (
              <Box 
                key={category}
                sx={{ 
                  display: activeStep === index ? 'block' : 'none',
                  px: { xs: 1, sm: 0 }
                }}
              >
                <Typography variant="h6" gutterBottom sx={{ 
                  fontSize: { xs: '1.1rem', sm: '1.25rem' },
                  mb: { xs: 2, sm: 3 },
                  display: { xs: 'none', sm: 'block' }
                }}>
                  {category}
                </Typography>

                {getQuestionsForCategory(category).map((q) => (
                  <Box key={q._id} sx={{ 
                    mb: { xs: 3, sm: 4 }
                  }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ 
                      fontSize: { xs: '0.95rem', sm: '1rem' }
                    }}>
                      {q.question}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph sx={{ 
                      fontSize: { xs: '0.85rem', sm: '0.875rem' }
                    }}>
                      {q.description}
                    </Typography>
                    <Box sx={{ 
                      display: 'grid', 
                      gridTemplateColumns: { xs: 'repeat(5, 1fr)', sm: 'repeat(10, 1fr)' },
                      gap: 1,
                      mb: 2
                    }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                        <Button
                          key={value}
                          variant={answers[q._id] === value ? 'contained' : 'outlined'}
                          onClick={(e) => {
                            e.preventDefault();
                            handleAnswer(q._id, value);
                          }}
                          sx={{ 
                            minWidth: { xs: '40px', sm: '60px' },
                            padding: { xs: '4px', sm: '8px' }
                          }}
                        >
                          {value}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                ))}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    Previous
                  </Button>
                  {activeStep === categories.length - 1 ? (
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={submitting || Object.values(answers).some(a => a === null)}
                    >
                      {submitting ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={getQuestionsForCategory(category).some(q => answers[q._id] === null)}
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default Survey; 