import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  ButtonGroup,
  Menu,
  MenuItem,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  Snackbar,
} from '@mui/material';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import axios from '../utils/axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import InviteTeam from './InviteTeam';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';

// Configureer axios basis URL
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// admin page
const Admin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [userForm, setUserForm] = useState({
    username: '',
    email: '',
    password: '',
    role: 'viewer',
    isActive: true,
  });
  const [token, setToken] = useState(localStorage.getItem('adminToken'));
  const [selectedCEO, setSelectedCEO] = useState(null);
  const [inviteForm, setInviteForm] = useState({
    teamMembers: [{ name: '', email: '' }]
  });
  const [inviteError, setInviteError] = useState('');
  const [inviteSuccess, setInviteSuccess] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
  const [questionForm, setQuestionForm] = useState({
    text: '',
    category: '',
    description: ''
  });
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [teamResults, setTeamResults] = useState([]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post('/api/admin/login', credentials);
      setToken(response.data.token);
      localStorage.setItem('adminToken', response.data.token);
      setIsLoggedIn(true);
      fetchStats();
      fetchUsers();
      fetchTeamResults();
    } catch (err) {
      setError(err.response?.data?.message || 'Ongeldige inloggegevens');
    }
  };

  const fetchStats = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/admin/stats');
      setStats(response.data);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van statistieken');
    }
    setLoading(false);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/admin/ceos');
      setUsers(response.data);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van CEO\'s');
    }
  };

  const fetchTeamResults = async () => {
    try {
      const response = await axios.get('/api/admin/team-results');
      setTeamResults(response.data);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van team resultaten');
    }
  };

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    
    // Titel
    doc.setFontSize(20);
    doc.text('Leadership Performance Scan Rapport', 14, 15);
    doc.setFontSize(12);

    // Basis Statistieken
    doc.setFontSize(16);
    doc.text('Basis Statistieken', 14, 30);
    doc.setFontSize(12);
    doc.text(`Totaal Aantal CEO's: ${stats.totalCEOs}`, 14, 40);
    doc.text(`Totaal Aantal Teamleden: ${stats.totalTeamMembers}`, 14, 50);
    doc.text(`Voltooide Enquêtes: ${stats.completedSurveys}`, 14, 60);
    doc.text(`Gemiddelde Teamgrootte: ${(stats.totalTeamMembers / stats.totalCEOs).toFixed(1)}`, 14, 70);

    // Recente Activiteit Tabel
    doc.setFontSize(16);
    doc.text('Recente Activiteit', 14, 90);
    doc.setFontSize(12);
    doc.autoTable({
      startY: 95,
      head: [['Datum', 'CEO', 'Bedrijf', 'Status', 'Team Grootte']],
      body: stats.recentActivity.map(activity => [
        new Date(activity.date).toLocaleDateString(),
        activity.ceoName,
        activity.companyName,
        activity.status,
        activity.teamSize
      ]),
      theme: 'grid'
    });

    // Categorie Scores
    doc.setFontSize(16);
    doc.text('Gemiddelde Scores per Categorie', 14, doc.lastAutoTable.finalY + 20);
    doc.setFontSize(12);
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 25,
      head: [['Categorie', 'Score']],
      body: stats.categoryScores.map(cat => [cat.category, cat.score]),
      theme: 'grid'
    });

    doc.save('leadership-quick-scan-rapport.pdf');
    handleExportClose();
  };

  const exportToExcel = async () => {
    try {
      // Gebruik de nieuwe server-side export functionaliteit
      const response = await axios.get('/api/admin/export-excel', {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Maak een URL voor de blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Maak een link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'leadership-quick-scan-team-resultaten.xlsx');
      
      // Voeg de link toe aan het document en klik erop
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
      
      handleExportClose();
    } catch (err) {
      console.error('Export error:', err);
      setError('Er is een fout opgetreden bij het exporteren naar Excel');
    }
  };

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    setInviteError('');
    setInviteSuccess('');

    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.post(
        `/api/admin/invite-team/${selectedCEO._id}`,
        { teamMembers: inviteForm.teamMembers },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setInviteSuccess('Team uitnodigingen zijn verstuurd');
      setInviteForm({ teamMembers: [{ name: '', email: '' }] });
      handleInviteClose();
    } catch (err) {
      setInviteError(err.response?.data?.message || 'Er is een fout opgetreden bij het versturen van de uitnodigingen');
    }
  };

  const addTeamMember = () => {
    setInviteForm({
      teamMembers: [...inviteForm.teamMembers, { name: '', email: '' }]
    });
  };

  const removeTeamMember = (index) => {
    const newTeamMembers = inviteForm.teamMembers.filter((_, i) => i !== index);
    setInviteForm({ teamMembers: newTeamMembers });
  };

  const updateTeamMember = (index, field, value) => {
    const newTeamMembers = [...inviteForm.teamMembers];
    newTeamMembers[index][field] = value;
    setInviteForm({ teamMembers: newTeamMembers });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 1) {
      fetchUsers();
    } else if (newValue === 2) {
      fetchQuestions();
      fetchAnswers();
    } else if (newValue === 3) {
      fetchTeamResults();
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await axios.get('/api/questions');
      setQuestions(response.data);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van de vragen');
    }
  };

  const fetchAnswers = async () => {
    try {
      const response = await axios.get('/api/survey/answers');
      setAnswers(response.data);
    } catch (err) {
      setError('Er is een fout opgetreden bij het ophalen van de antwoorden');
    }
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedQuestion) {
        await axios.put(`/api/questions/${selectedQuestion._id}`, questionForm);
      } else {
        await axios.post('/api/questions', questionForm);
      }
      setQuestionDialogOpen(false);
      setQuestionForm({ text: '', category: '', description: '' });
      setSelectedQuestion(null);
      fetchQuestions();
    } catch (err) {
      setError('Er is een fout opgetreden bij het opslaan van de vraag');
    }
  };

  const handleEditQuestion = (question) => {
    setSelectedQuestion(question);
    setQuestionForm({
      text: question.text,
      category: question.category,
      description: question.description
    });
    setQuestionDialogOpen(true);
  };

  const handleDeleteQuestion = async (questionId) => {
    if (window.confirm('Weet u zeker dat u deze vraag wilt verwijderen?')) {
      try {
        await axios.delete(`/api/questions/${questionId}`);
        fetchQuestions();
      } catch (err) {
        setError('Er is een fout opgetreden bij het verwijderen van de vraag');
      }
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserForm({
      username: user.username,
      email: user.email,
      password: '',
      role: user.role || 'viewer',
      isActive: user.isActive,
    });
    setUserDialogOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Weet u zeker dat u deze gebruiker wilt verwijderen?')) {
      try {
        await axios.delete(`/api/admin/ceos/${userId}`);
        fetchUsers(); // Herlaad de gebruikerslijst
      } catch (err) {
        setError('Er is een fout opgetreden bij het verwijderen van de gebruiker');
      }
    }
  };

  const handleInviteClose = () => {
    setInviteDialogOpen(false);
    setInviteForm({ teamMembers: [{ name: '', email: '' }] });
    setInviteError('');
    setInviteSuccess('');
  };

  const handleInviteOpen = (ceo) => {
    setSelectedCEO(ceo);
    setInviteDialogOpen(true);
  };

  const handleInviteAuditTeam = async (ceoId) => {
    try {
      setLoading(true);
      await axios.post(`/api/admin/invite-audit-team/${ceoId}`);
      setSnackbar({
        open: true,
        message: 'Audit scan invitations sent successfully',
        severity: 'success'
      });
      // Refresh team results to update the UI
      fetchTeamResults();
    } catch (error) {
      console.error('Error sending audit scan invitations:', error);
      setSnackbar({
        open: true,
        message: 'An error occurred while sending audit scan invitations',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async (ceoId) => {
    try {
      const response = await axios.get(`/api/results/${ceoId}/excel`, {
        responseType: 'blob'
      });
      
      // Maak een URL voor de blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Maak een link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `leadership-scan-${ceoId}.xlsx`);
      
      // Voeg de link toe aan het document en klik erop
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Er is een fout opgetreden bij het exporteren naar Excel');
      console.error('Error exporting to Excel:', err);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Rapportages
              </Typography>
              {stats && (
                <>
                  {/* Basis Statistieken */}
                  <Grid item xs={12} md={3}>
                    <Paper sx={{ p: 2, textAlign: 'center' }}>
                      <Typography variant="h6">Totaal Aantal CEO's</Typography>
                      <Typography variant="h4">{stats.totalCEOs}</Typography>
                    </Paper>
                  </Grid>
                  {/* ... rest of the reporting content ... */}
                </>
              )}
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                CEO's
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Naam</TableCell>
                      <TableCell>Bedrijf</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Acties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((ceo) => (
                      <TableRow key={ceo._id}>
                        <TableCell>{ceo.name}</TableCell>
                        <TableCell>{ceo.companyName}</TableCell>
                        <TableCell>{ceo.status}</TableCell>
                        <TableCell>
                          <ButtonGroup>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleEditUser(ceo)}
                            >
                              Bewerken
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleDeleteUser(ceo._id)}
                            >
                              Verwijderen
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant="h4">Vragen & Antwoorden</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setQuestionDialogOpen(true)}
                >
                  Nieuwe Vraag
                </Button>
              </Box>
              
              {/* Vragen Tabel */}
              <Paper sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ p: 2 }}>Vragen</Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Vraag</TableCell>
                        <TableCell>Categorie</TableCell>
                        <TableCell>Beschrijving</TableCell>
                        <TableCell>Acties</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {questions.map((question) => (
                        <TableRow key={question._id}>
                          <TableCell>{question.text}</TableCell>
                          <TableCell>{question.category}</TableCell>
                          <TableCell>{question.description}</TableCell>
                          <TableCell>
                            <ButtonGroup>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditQuestion(question)}
                              >
                                Bewerken
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleDeleteQuestion(question._id)}
                              >
                                Verwijderen
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              {/* Antwoorden Tabel */}
              <Paper>
                <Typography variant="h6" sx={{ p: 2 }}>Antwoorden</Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>CEO</TableCell>
                        <TableCell>Teamlid</TableCell>
                        <TableCell>Vraag</TableCell>
                        <TableCell>Antwoord</TableCell>
                        <TableCell>Datum</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {answers.map((answer) => (
                        <TableRow key={answer._id}>
                          <TableCell>{answer.ceoName}</TableCell>
                          <TableCell>{answer.teamMemberName}</TableCell>
                          <TableCell>{answer.questionText}</TableCell>
                          <TableCell>{answer.answer}</TableCell>
                          <TableCell>{new Date(answer.createdAt).toLocaleDateString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6">Team Resultaten</Typography>
              <Button
                variant="contained"
                onClick={handleExportClick}
                startIcon={<DownloadIcon />}
                disabled={!teamResults || teamResults.length === 0}
              >
                Exporteer Alle
              </Button>
            </Box>

            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : !teamResults || teamResults.length === 0 ? (
              <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography>Er zijn nog geen team resultaten beschikbaar.</Typography>
              </Box>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Bedrijf</TableCell>
                      <TableCell>Team Grootte</TableCell>
                      <TableCell>Voltooide Enquêtes</TableCell>
                      <TableCell>Engage</TableCell>
                      <TableCell>Equip</TableCell>
                      <TableCell>Empower</TableCell>
                      <TableCell>Evolve</TableCell>
                      <TableCell>Acties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teamResults.map((result) => {
                      const avgScore = Object.values(result.categories).reduce((a, b) => a + parseFloat(b), 0) / Object.keys(result.categories).length;
                      return (
                        <TableRow key={result._id}>
                          <TableCell>{result.companyName}</TableCell>
                          <TableCell>{result.teamSize}</TableCell>
                          <TableCell>{result.completedSurveys}</TableCell>
                          <TableCell>{parseFloat(result.categories.Engage).toFixed(2)}</TableCell>
                          <TableCell>{parseFloat(result.categories.Equip).toFixed(2)}</TableCell>
                          <TableCell>{parseFloat(result.categories.Empower).toFixed(2)}</TableCell>
                          <TableCell>{parseFloat(result.categories.Evolve).toFixed(2)}</TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleExportExcel(result._id)}
                              sx={{ mr: 1 }}
                            >
                              Export XLS
                            </Button>
                            {result.auditScanStatus === 'sent' ? (
                              <Typography variant="body2" color="textSecondary">
                                Audit Scan verstuurd
                              </Typography>
                            ) : (
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => handleInviteAuditTeam(result._id)}
                              >
                                Stuur Audit Scan
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        );
      default:
        return null;
    }
  };

  if (!isLoggedIn) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            py: 4,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              width: '100%',
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom align="center">
              Admin Login
            </Typography>

            <form onSubmit={handleLogin}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Gebruikersnaam"
                    value={credentials.username}
                    onChange={(e) =>
                      setCredentials({ ...credentials, username: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Wachtwoord"
                    type="password"
                    value={credentials.password}
                    onChange={(e) =>
                      setCredentials({ ...credentials, password: e.target.value })
                    }
                  />
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                  >
                    Inloggen
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </Container>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Overview" />
            <Tab label="Users" />
            <Tab label="Questions & Answers" />
            <Tab label="Results" />
          </Tabs>
        </Box>
        {renderTabContent()}
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Invite Team Dialog */}
      <Dialog open={inviteDialogOpen} onClose={handleInviteClose} maxWidth="md" fullWidth>
        <DialogTitle>Nodig Team Leden Uit</DialogTitle>
        <DialogContent>
          <form onSubmit={handleInviteSubmit}>
            <Grid container spacing={2}>
              {inviteForm.teamMembers.map((member, index) => (
                <Grid item xs={12} key={index}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label="Naam (optioneel)"
                        value={member.name}
                        onChange={(e) => updateTeamMember(index, 'name', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        required
                        fullWidth
                        label="E-mail"
                        type="email"
                        value={member.email}
                        onChange={(e) => updateTeamMember(index, 'email', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        color="error"
                        onClick={() => removeTeamMember(index)}
                        disabled={inviteForm.teamMembers.length === 1}
                      >
                        Verwijderen
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {inviteForm.teamMembers.length < 8 && (
                <Grid item xs={12}>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={addTeamMember}
                    variant="outlined"
                  >
                    Teamlid Toevoegen
                  </Button>
                </Grid>
              )}
              {inviteError && (
                <Grid item xs={12}>
                  <Alert severity="error">{inviteError}</Alert>
                </Grid>
              )}
              {inviteSuccess && (
                <Grid item xs={12}>
                  <Alert severity="success">{inviteSuccess}</Alert>
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInviteClose}>Annuleren</Button>
          <Button onClick={handleInviteSubmit} variant="contained" color="primary">
            Uitnodigingen Versturen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Vraag Dialog */}
      <Dialog open={questionDialogOpen} onClose={() => setQuestionDialogOpen(false)}>
        <DialogTitle>
          {selectedQuestion ? 'Vraag Bewerken' : 'Nieuwe Vraag'}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleQuestionSubmit}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Vraag"
                  value={questionForm.text}
                  onChange={(e) => setQuestionForm({ ...questionForm, text: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Categorie"
                  value={questionForm.category}
                  onChange={(e) => setQuestionForm({ ...questionForm, category: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Beschrijving"
                  value={questionForm.description}
                  onChange={(e) => setQuestionForm({ ...questionForm, description: e.target.value })}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setQuestionDialogOpen(false)}>Annuleren</Button>
          <Button onClick={handleQuestionSubmit} variant="contained" color="primary">
            {selectedQuestion ? 'Opslaan' : 'Toevoegen'}
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={exportAnchorEl}
        open={Boolean(exportAnchorEl)}
        onClose={handleExportClose}
      >
        <MenuItem onClick={exportToExcel}>Exporteer naar Excel</MenuItem>
      </Menu>
    </Container>
  );
};

export default Admin; 