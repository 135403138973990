import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import axios from '../utils/axios';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [ceo, setCEO] = useState(null);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [inviteForm, setInviteForm] = useState({
    teamMembers: [{ name: '', email: '' }]
  });
  const [inviteError, setInviteError] = useState('');
  const [inviteSuccess, setInviteSuccess] = useState('');

  useEffect(() => {
    const fetchCEOData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/');
          return;
        }

        const response = await axios.get('/api/auth/ceo-profile', {
          headers: { Authorization: `Bearer ${token}` }
        });

        setCEO(response.data);
        setTeamMembers(response.data.teamMembers || []);
        setLoading(false);
      } catch (err) {
        setError('An error occurred while loading your profile');
        setLoading(false);
      }
    };

    fetchCEOData();
  }, [navigate]);

  const handleInviteOpen = () => {
    setInviteDialogOpen(true);
  };

  const handleInviteClose = () => {
    setInviteDialogOpen(false);
    setInviteForm({ teamMembers: [{ name: '', email: '' }] });
    setInviteError('');
    setInviteSuccess('');
  };

  const addTeamMember = () => {
    if (inviteForm.teamMembers.length < 8) {
      setInviteForm({
        teamMembers: [...inviteForm.teamMembers, { name: '', email: '' }]
      });
    }
  };

  const removeTeamMember = (index) => {
    const newTeamMembers = inviteForm.teamMembers.filter((_, i) => i !== index);
    setInviteForm({ teamMembers: newTeamMembers });
  };

  const updateTeamMember = (index, field, value) => {
    const newTeamMembers = [...inviteForm.teamMembers];
    newTeamMembers[index][field] = value;
    setInviteForm({ teamMembers: newTeamMembers });
  };

  const handleInviteSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/api/auth/invite-team',
        { ceoId: ceo._id, teamMembers: inviteForm.teamMembers },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setInviteSuccess('Team invitations have been sent');
      handleInviteClose();
      
      // Update team members list
      const response = await axios.get('/api/auth/ceo-profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTeamMembers(response.data.teamMembers || []);
    } catch (err) {
      setInviteError(err.response?.data?.message || 'An error occurred while sending invitations');
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Dashboard
          </Typography>

          <Typography variant="subtitle1" gutterBottom align="center" color="text.secondary">
            Welcome, {ceo?.name}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Team Members</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleInviteOpen}
              >
                Invite Team Members
              </Button>
            </Box>

            <List>
              {teamMembers.map((member, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={member.name || 'Anonymous'}
                    secondary={member.email}
                  />
                  <ListItemSecondaryAction>
                    <Typography color={member.status === 'completed' ? 'success.main' : 'warning.main'}>
                      {member.status === 'completed' ? 'Completed' : 'Pending'}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      </Box>

      {/* Invite Team Dialog */}
      <Dialog open={inviteDialogOpen} onClose={handleInviteClose} maxWidth="sm" fullWidth>
        <DialogTitle>Invite Team Members</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {inviteForm.teamMembers.map((member, index) => (
              <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="Name (optional)"
                    value={member.name}
                    onChange={(e) => updateTeamMember(index, 'name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    type="email"
                    value={member.email}
                    onChange={(e) => updateTeamMember(index, 'email', e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color="error"
                    onClick={() => removeTeamMember(index)}
                    disabled={inviteForm.teamMembers.length === 1}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            {inviteForm.teamMembers.length < 8 && (
              <Button
                startIcon={<AddIcon />}
                onClick={addTeamMember}
                sx={{ mb: 2 }}
              >
                Add Team Member
              </Button>
            )}

            {inviteError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {inviteError}
              </Alert>
            )}

            {inviteSuccess && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {inviteSuccess}
              </Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInviteClose}>Cancel</Button>
          <Button onClick={handleInviteSubmit} variant="contained" color="primary">
            Send Invitations
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Dashboard; 