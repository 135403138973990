import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

// Pages
import Home from './pages/Home';
import Welcome from './pages/Welcome';
import InviteTeam from './pages/InviteTeam';
import Survey from './pages/Survey';
import Results from './pages/Results';
import ThankYou from './pages/ThankYou';
import Admin from './pages/Admin';
import Dashboard from './pages/Dashboard';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/invite-team" element={<InviteTeam />} />
          <Route path="/survey/:token" element={<Survey />} />
          <Route path="/results/:ceoId" element={<Results />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App; 