import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  LinearProgress,
  Chip,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from 'recharts';
import axios from '../utils/axios';

const Results = () => {
  const { ceoId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [results, setResults] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axios.get(`/api/results/${ceoId}`);
        setResults(response.data);
      } catch (err) {
        setError('An error occurred while fetching the results');
        console.error('Error fetching results:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [ceoId]);

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.get(`/api/results/${ceoId}/pdf`, {
        responseType: 'blob'
      });
      
      // Maak een URL voor de blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Maak een link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `leadership-report-${results.companyName}.pdf`);
      
      // Voeg de link toe aan het document en klik erop
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Er is een fout opgetreden bij het downloaden van het rapport');
      console.error('Error downloading PDF:', err);
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const response = await axios.get(`/api/results/${ceoId}/excel`, {
        responseType: 'blob'
      });
      
      // Maak een URL voor de blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Maak een link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `leadership-scan-${results.companyName}.xlsx`);
      
      // Voeg de link toe aan het document en klik erop
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Er is een fout opgetreden bij het downloaden van de Excel');
      console.error('Error downloading Excel:', err);
    }
  };

  const getScoreColor = (score) => {
    if (score >= 7.5) return 'success';
    if (score >= 5) return 'warning';
    return 'error';
  };

  const getScoreMessage = (score) => {
    if (score >= 7.5) return "You have a strong leadership team.";
    if (score >= 5) return "Your team is on its way, but not yet mature.";
    return "You have a group of managers, not a leadership team.";
  };

  const getScoreDescription = (score) => {
    if (score >= 7.5) return "There is ownership, mutual trust, clear roles, and a shared direction. This team carries the organization and makes scalable growth possible.";
    if (score >= 5) return "There's some collaboration and shared ambition, but the team is still vulnerable. Hidden conflict, silo thinking, or a lack of shared execution power may be holding it back.";
    return "There's a lack of alignment, mutual trust, and shared responsibility. Decisions are fragmented or top-down, and the organization leans too heavily on the CEO.";
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  if (!results) {
    return (
      <Container maxWidth="lg">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert severity="info">No results found</Alert>
        </Box>
      </Container>
    );
  }

  const chartData = results.results?.map(result => ({
    name: result.category,
    teamScore: parseFloat(result.average),
    ceoScore: parseFloat(result.ceoScore || 0),
    isControl: result.isControl || false
  })).filter(result => !result.isControl) || [];

  const averageScore = chartData.reduce((acc, curr) => {
    const teamScore = curr.teamScore;
    const ceoScore = curr.ceoScore;
    return acc + (teamScore + ceoScore) / 2;
  }, 0) / chartData.length;
  const averageCeoScore = chartData.reduce((acc, curr) => acc + curr.ceoScore, 0) / chartData.length;

  // Calculate total team members including CEO
  const totalTeamMembers = (results.progress?.total || 0) + 1;
  const completedResponses = results.progress?.completed || 0;
  const progressPercentage = Math.min(Math.round((completedResponses / totalTeamMembers) * 100), 100);

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Results for {results.companyName}
        </Typography>

        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Overall Score
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <Typography variant="h3" component="div" sx={{ color: `${getScoreColor(averageScore)}.main` }}>
              {averageScore.toFixed(1)}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              / 10
            </Typography>
          </Box>
          <Chip 
            label={getScoreMessage(averageScore)} 
            color={getScoreColor(averageScore)} 
            sx={{ mb: 2 }}
          />
          <Typography variant="body1">
            {getScoreDescription(averageScore)}
          </Typography>
        </Paper>

        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Progress
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box sx={{ flexGrow: 1, mr: 2 }}>
              <LinearProgress
                variant="determinate"
                value={progressPercentage}
                sx={{ height: 10, borderRadius: 5 }}
              />
            </Box>
            <Typography variant="body2" color="text.secondary">
              {completedResponses} of {totalTeamMembers} team members
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {progressPercentage}% of team members have responded
          </Typography>
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Scores per category
          </Typography>
          <Box sx={{ height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 10]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="teamScore" name="Team Gemiddelde" fill="#8884d8" />
                <Bar dataKey="ceoScore" name="CEO Score" fill="#2196f3" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Paper>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            onClick={handleDownloadPDF}
            sx={{ mr: 2 }}
          >
            Download PDF Report
          </Button>
          
          <Button
            variant="outlined"
            size="large"
            href="mailto:geert@ballsmedia.com"
            target="_blank"
          >
            Schedule Strategic Meeting
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Results; 