import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios';

const ThankYou = () => {
  const { ceoId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [ceo, setCEO] = useState(null);

  useEffect(() => {
    const fetchCEO = async () => {
      try {
        const response = await axios.get(`/api/ceo/${ceoId}/status`);
        setCEO(response.data);
      } catch (err) {
        setError('An error occurred while fetching the information');
        console.error('Error fetching CEO:', err);
      } finally {
        setLoading(false);
      }
    };

    if (ceoId) {
      fetchCEO();
    } else {
      setLoading(false);
    }
  }, [ceoId]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            textAlign: 'center',
            width: '100%',
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: 64,
              color: 'success.main',
              mb: 2,
            }}
          />
          <Typography variant="h4" component="h1" gutterBottom>
            Thank you for your participation!
          </Typography>
          <Typography variant="body1" paragraph>
            {ceo ? (
              ceo.status === 'completed' 
                ? 'All team members have completed the survey. You will receive an email with the results shortly.'
                : 'Your answers have been successfully saved. The CEO will receive the results once all team members have responded.'
            ) : (
              'Your invitations have been successfully sent. You will receive an email once all team members have responded.'
            )}
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default ThankYou; 